import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';

import { IFilter } from '@reducers/filter';
import { IActiveMetrics, MetricsMetaState } from '@reducers/analytics';
import WalkThrough from '../../../utils/Walkthrough';
import { cellSize } from '../../../utils';
import MetricsProvider from './MetricsProvider';
import TabsProvider from './TabsProvider';

interface IProps {
  section: 'user' | 'diagnostics';

  filters: Record<string, IFilter>;
  filterId?: string;

  meta: Record<string, MetricsMetaState>;

  activeMetrics: Record<string, IActiveMetrics>;
  toggleMetric: (
    filterId: string,
    section: 'user' | 'diagnostics',
    metricId: string
  ) => void;
}

/**
 * @description Provides filter based grid for analytics tabs
 * @param       properties
 * @returns     React Elements
 */
const TabContent: FunctionComponent<IProps> = ({
  activeMetrics,
  filterId,
  filters,
  meta,
  section,
  toggleMetric,
}: IProps) => {
  const filterKeys = Object.keys(filters).filter(
    (k) => (!filterId && k !== 'simulator' && k !== 'popup') || k === filterId
  );

  return (
    <Box className="tab-content-inner" p={2}>
      <Grid container spacing={2} flex={1}>
        {filterKeys.map((id, index) => (
          <Grid
            key={id}
            container
            item
            xs={cellSize(filterKeys, index)}
            direction="column"
            flex={1}
            style={{
              display: 'flex',
              minHeight: '100%',
            }}
          >
            <MetricsProvider section={section} filterId={id}>
              <TabsProvider
                filterId={id}
                activeMetrics={activeMetrics[id] || {}}
                section={section}
                meta={meta[id] || {}}
                toggleMetric={toggleMetric}
              />
            </MetricsProvider>
          </Grid>
        ))}
      </Grid>
      <WalkThrough type="analytics" />
    </Box>
  );
};
TabContent.defaultProps = {
  filterId: undefined,
};

export default TabContent;
