import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { createScan } from '@actions/index';
import { IAppState } from '@reducers/index';
import Routing from './Routing';

const mapStateToProps = (state: IAppState) => ({
  loading: state.scans.scan.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createScan: (scan: string, x: number, y: number, z: number) =>
    dispatch(createScan.request({ scan, type: 'location', x, y, z }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
